import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the PurchaseOrderVoidRequests Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ 'item', 'orderId', 'quantity' ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  // Filtra os registros pelo número do pedido
  filterByOrderId() {
    var orderId = this.orderIdTarget.value 
    this.stimulate("PurchaseOrderVoidRequests#filter_by_order_id", orderId) 
      .then(() => {
        this.orderIdTarget.value = orderId
      })
  }

  // Mostra todos as solicitações (limpa o filtro)
  showAll() {
    this.stimulate("PurchaseOrderVoidRequests#filter_by_order_id", null) 
    this.orderIdTarget.value = null 
  }

  // Atualiza o status dos itens da solicitaçào de distrato. Só atualiza a página se a operação no backend for bem sucedida. 
  toggleAll() {
    var newValue = event.target.checked
    // Cria uma array contendo somente os "ids" dos itens.
    var ids = this.itemTargets.map(function(item) { return item["dataset"]["id"]})
    this.stimulate("PurchaseOrderVoidRequests#toggle_all", ids, newValue)
      .then(() => {
        this.itemTargets.forEach(element => {
          element.checked = newValue
        });
      }).catch((error) => {
        event.target.checked = !newValue
        alert("Erro atualizando status dos items: " + error)
      })
  }
}
