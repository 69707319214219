import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the SupplierEvaluation Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */
  
  static targets = [ "itemTotal", "totalScore", "totalScoreLabel" ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  afterSelect(element, reflex, error, reflexId) {
    // $("#evalModal").modal("show")
  }

  calculateScore () {
    // event retorna o controle (HTML) que foi modificado
    var itemId = event.target.dataset.itemid
    var total =
      parseInt(event.target.dataset.weight) * parseInt(event.target.value) / 100
    var format = this.setTotalFormat(event.target.value / 10)
    this.updateItemTotal(itemId, total, format)
    this.calculateTotalScore()
  }

  calculateTotalScore () {
    var total = 0
    for (let index = 0; index < this.itemTotalTargets.length; index++) {
      const item = this.itemTotalTargets[index];
      total += parseFloat(item.innerHTML)
    }
    var labelConfig = this.setTotalFormat(total)
    this.totalScoreTarget.value = total.toFixed(2)
    this.totalScoreLabelTarget.innerHTML = total.toFixed(2)
    this.totalScoreLabelTarget.classList = `label label-bg ${labelConfig}` 
  }

  delete () {
    if (confirm("Confirma exclusão da avaliação?"))
      this.stimulate("SupplierEvaluation#delete")
    end
  }

  setTotalFormat (value) {
    var color = ''
    if (value <= 3.5)
      color = 'label-danger'
    else if (value > 3.5 && value <= 7.0) 
      color = 'label-warning'
    else
      color = 'label-success'
    return color
  }

  updateItemTotal (itemId, total, format) {
    for (let index = 0; index < this.itemTotalTargets.length; index++) {
      const target = this.itemTotalTargets[index];
      if (target.id === `total_${ itemId }`) {
        target.innerHTML = total.toFixed(2)
        target.classList = `label label-bg ${ format }`
      }
    }
  }
  

}
