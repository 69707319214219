import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the SupplierAassessmentQuestion Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = ['contentToPrint']
  
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  print() {
    const elementToPrint =  this.contentToPrintTarget.innerHTML

    if (elementToPrint) {
      const printContents = '<html>' +
                          '<head>' +
                          '<link href="/assets/twitter-bootstrap-static/bootstrap.css?body=1" ' +
                          'media="all" rel="stylesheet" type="text/css" />' +
                          '<link href="/assets/bootstrap_and_overrides.css?body=1"' +
                          'media="all" rel="stylesheet" type="text/css" />' +
                          '</head>' +
                          '<body>' + elementToPrint +
                          '<script src="/assets/angular.js?body=1" type="text/javascript">' +
                          '</script>' +
                          '</body>' +
                          '</html>';
          const popupWin = window.open(null, 'popup', 'width=500,height=500');
          popupWin.document.open();
          popupWin.document.write(printContents);
          popupWin.document.close();
          // opens print dialog
          popupWin.focus();
          setTimeout(() => {
            popupWin.print();
          }, 1500);
        }
  }
  
}
